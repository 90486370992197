import React, { useEffect } from 'react'

const Scrolling = () => {
  useEffect(() => {
    var height = document.body.scrollHeight - window.innerHeight - 400;
    setInterval(function () {
      if(window.pageYOffset < 400 || window.pageYOffset > height) {
        document.querySelector('.fixed--button').classList.remove('active');
      } else {
        document.querySelector('.fixed--button').classList.add('active');
      } 
    }, 200);

  }, [])
  return (
  <></>
  )
}

export default Scrolling
