import * as React from "react"

const Footer = () => {
  const openMenu = () => {
    document.body.classList.add('newsletter-open');
  }
  return (
  <footer className='p20 pl30 pr30 flex m-p20'>
    <p className='m0'>© {new Date().getFullYear()} | CB</p>
    <p className='m0 mla pointer' onClick={openMenu} role='presentation'>Join My Newsletter</p>
  </footer>
  )
}
export default Footer
